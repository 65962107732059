// Foundation for Sites by ZURB
// foundation.zurb.com
// Licensed under MIT Open Source
////
/// @group top-bar
////
/// Padding for the top bar.
/// @type Number
$topbar-padding: 0.5rem !default;
/// Background color for the top bar. This color also cascades to menus within the top bar.
/// @type Color
$topbar-background: $light-gray !default;
/// Color for links inside a top bar menu.
/// @type Color
$topbar-link-color: $primary-color !default;
/// Width of `<input>` elements inside the top bar.
/// @type Number
$topbar-input-width: 200px !default;
/// Adds styles for a top bar container.
@mixin top-bar-container {
    @include clearfix;
    padding: $topbar-padding;
    &,
    ul {
        background-color: $topbar-background;
    }
    a {
        color: $topbar-link-color;
    }
    input {
        width: $topbar-input-width;
        margin-#{$global-right}: 1rem;
    }
    input.button {
        width: auto;
    }
}

/// makes sections stacked
@mixin top-bar-stacked {
    // Sub-sections
    .top-bar-right {
        width: 100%;
    }
    .top-bar-left {
        width: 100%;
    }
}

@mixin foundation-top-bar {
    // Top bar container
    .top-bar {
        @include top-bar-container;
    }
    // Generate classes for stacking on each screen size (defined in $breakpoint-classes)
    @each $size in $breakpoint-classes {
        .stacked-for-#{$size} {
            @include breakpoint($size down) {
                @include top-bar-stacked;
            }
        }
    }
    // Stack on small screens as default
    @include breakpoint(small only) {
        @include top-bar-stacked;
    }
    // Sub-sections
    .top-bar-left {
        float: left;
    }
    .top-bar-right {
        float: right;
    }
}