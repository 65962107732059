                                    @charset 'utf-8';
                                    @import 'settings';
                                    @import 'foundation';
                                    @import 'motion-ui';
                                    @include foundation-global-styles;
                                    @include foundation-grid;
                                    @include foundation-typography;
                                    @include foundation-button;
                                    @include foundation-forms;
                                    @include foundation-visibility-classes;
                                    @include foundation-float-classes;
                                    @include foundation-accordion;
                                    @include foundation-badge;
                                    @include foundation-breadcrumbs;
                                    @include foundation-button-group;
                                    @include foundation-callout;
                                    @include foundation-close-button;
                                    @include foundation-drilldown-menu;
                                    @include foundation-dropdown;
                                    @include foundation-dropdown-menu;
                                    @include foundation-flex-video;
                                    @include foundation-label;
                                    @include foundation-media-object;
                                    @include foundation-menu;
                                    @include foundation-off-canvas;
                                    @include foundation-orbit;
                                    @include foundation-pagination;
                                    @include foundation-progress-bar;
                                    @include foundation-slider;
                                    @include foundation-sticky;
                                    @include foundation-reveal;
                                    @include foundation-switch;
                                    @include foundation-table;
                                    @include foundation-tabs;
                                    @include foundation-thumbnail;
                                    @include foundation-title-bar;
                                    @include foundation-tooltip;
                                    @include foundation-top-bar;
                                    @include motion-ui-transitions;
                                    @include motion-ui-animations;
                                    body {
                                        font-family: "Raleway";
                                        background: url(/assets/img/bg_papier_gross.jpg) transparent;
                                    }
                                    
                                    .accordion {
                                        list-style-type: none;
                                        border: 0px solid #023838;
                                        border-radius: 0;
                                        margin-left: 0;
                                        background-color: rgba(217, 194, 171, 0);
                                    }
                                    
                                    .accordion-content {
                                        padding: 1rem;
                                        display: none;
                                        border-bottom: 1px solid rgb(0, 0, 0);
                                        background-color: rgba(250, 246, 242, 0.24);
                                    }
                                    
                                    .accordion-title {
                                        display: block;
                                        padding: 1rem 1rem;
                                        line-height: 1;
                                        font-size: 1.0rem;
                                        color: rgb(0, 0, 0);
                                        position: relative;
                                        border-bottom: 1px solid rgb(0, 0, 0);
                                        border-top: 0px solid rgb(0, 0, 0);
                                        background-color: rgba(232, 217, 203, 0.15);
                                    }
                                    
                                    .italic {
                                        font-style: italic;
                                    }
                                    
                                    .hr_schwarz {
                                        background: rgb(0, 0, 0);
                                        height: 1px;
                                        border: none;
                                        margin-top: 0.25rem;
                                        margin-left: 0.75rem;
                                    }

									.hr_weiss {
                                        background: rgb(248, 248, 255);
                                        height: 1px;
                                        border: none;
                                        margin-top: 0.25rem;
                                        margin-left: 0.75rem;
                                    }
                                    
                                    .hr_schwarz2 {
                                        background: rgb(0, 0, 0);
                                        height: 1px;
                                        border: none;
                                        margin-left: 0.0rem;
                                        margin-top: 0.3rem;
                                    }
                                    
                                    .hr_green_alfa {
                                        background: rgba(2, 60, 56, 0);
                                        height: 1px;
                                        border: none;
                                        margin: 1.0rem;
                                    }
                                    
                                    .hr_transp_2rem {
                                        background: rgba(2, 60, 56, 0);
                                        height: 0px;
                                        border: none;
                                        margin-top: 2.0rem;
                                    }
                                    
                                    .hr_green_alfa_small {
                                        background: rgba(2, 60, 56, 0);
                                        height: 1px;
                                        border: none;
                                        margin: -5px;
                                    }
                                    
                                    .hr_green_alfa_txt {
                                        background: rgba(2, 60, 56, 0);
                                        height: 1px;
                                        border: none;
                                        margin: 10px;
                                    }
                                    
                                    .hr_row {
                                        background: rgba(3, 3, 3, 0.17);
                                        height: 1px;
                                        border: none;
                                        margin: 1.0rem;
                                    }
                                    
                                    .link1 a:link,
                                    .link1 a:visited {
                                        color: #ffffff;
                                    }
                                    
                                    .link1 a:hover,
                                    .link1 a:active {
                                        color: rgb(239, 231, 216);
                                    }
                                    
                                    .owl_top {
                                        margin-top: 25px;
                                    }
                                    
                                    .owl_top_small {
                                        margin-top: 10px;
                                    }
                                    
                                    .color_darkgreen {
                                        color: rgb(2, 60, 56);
                                    }
                                    
                                    .color_white {
                                        color: rgb(253, 252, 246);
                                    }
                                    
                                    .img_subtext {
                                        color: rgb(253, 252, 246);
                                        font-size: 0.9rem;
                                    }
                                    
                                    .flex-caption {
                                        bottom: 30px;
                                        color: rgb(217, 194, 171);
                                        font-size: 35px;
                                        line-height: 45px;
                                        font-weight: r;
                                        left: 0;
                                        padding: 0 30px;
                                        position: absolute;
                                        right: 0;
                                        text-transform: none;
                                        z-index: 1;
                                    }
                                    
                                    .flex-caption_02 {
                                        bottom: 10px;
                                        color: rgb(217, 194, 171);
                                        font-size: 40px;
                                        font-weight: thin;
                                        line-height: 20px;
                                        padding: 30px 30px;
                                        padding-right: 80px;
                                        position: absolute;
                                        left: 0;
                                        text-transform: none;
                                        z-index: 1;
                                    }
                                    
                                    .flex-caption_txt_saison {
                                        bottom: 40px;
                                        color: rgb(217, 194, 171);
                                        font-size: 60px;
                                        font-weight: 400;
                                        line-height: 55px;
                                        padding: 0px 40px;
                                        position: absolute;
                                        right: 0;
                                        text-shadow: 1px 1px #000000;
                                        text-transform: none;
                                        z-index: 1;
                                    }
                                    
                                    .flex-caption_txt_saison_02 {
                                        bottom: 30px;
                                        color: rgb(217, 194, 171);
                                        font-size: 20px;
                                        font-weight: 400;
                                        line-height: 30px;
                                        padding: 35px 35px 35px 35px;
                                        position: absolute;
                                        right: 40px;
                                        text-shadow: 1px 1px #000000;
                                        text-transform: none;
                                        z-index: 1;
                                        background-color: rgba(2, 60, 56, 0.7);
                                    }
                                    
                                    .flex-caption_txt_preview {
                                        top: 0px;
                                        color: rgb(255, 255, 255);
                                        font-size: 19px;
                                        font-weight: 500;
                                        line-height: 26px;
                                        padding: 28px 28px 300px 28px;
                                        position: absolute;
                                        left: 0px;
                                        text-transform: none;
                                        z-index: 1;
                                        background-color: rgba(2, 60, 56, 0.7);
                                    }
                                    
                                    .flex-caption_txt_preview_content {
                                        top: 90px;
                                        color: rgb(255, 255, 255);
                                        font-size: 18px;
                                        font-weight: 300;
                                        line-height: 19px;
                                        padding: 35px 35px 35px 35px;
                                        position: absolute;
                                        left: 0px;
                                        text-transform: none;
                                        z-index: 1;
                                    }
                                    
                                    .flex-caption_gruen {
                                        bottom: 30px;
                                        color: rgb(2, 60, 56);
                                        font-size: 35px;
                                        font-weight: 600;
                                        line-height: 45px;
                                        left: 0;
                                        padding: 0 30px;
                                        position: absolute;
                                        right: 0;
                                        text-transform: none;
                                        z-index: 1;
                                    }
                                    
                                    .flex-caption_small {
                                        bottom: 15px;
                                        color: rgb(234, 214, 195);
                                        font-size: 20px;
                                        font-weight: thin;
                                        text-shadow: 1px 1px #000000;
                                        line-height: 45px;
                                        left: 0;
                                        padding: 0 30px;
                                        position: absolute;
                                        right: 0;
                                        text-transform: none;
                                        z-index: 1;
                                    }
                                    
                                    .flex-caption_small_top {
                                        top: 15px;
                                        color: rgb(234, 214, 195);
                                        font-size: 20px;
                                        font-weight: thin;
                                        text-shadow: 1px 1px #000000;
                                        line-height: 45px;
                                        left: 0;
                                        padding: 0 30px;
                                        position: absolute;
                                        right: 0;
                                        text-transform: none;
                                        z-index: 1;
                                    }
                                    
                                    .flex-caption_small_grey {
                                        bottom: 15px;
                                        color: rgb(100, 100, 100);
                                        font-size: 20px;
                                        font-weight: thin;
                                        line-height: 45px;
                                        left: 0;
                                        padding: 0 30px;
                                        position: absolute;
                                        right: 0;
                                        text-transform: none;
                                        z-index: 1;
                                    }
                                    
                                    .flex-caption_saison_left {
                                        bottom: 25px;
                                        color: rgb(234, 214, 195);
                                        font-size: 40px;
                                        font-weight: thin;
                                        text-shadow: 1px 1px #000000;
                                        line-height: 45px;
                                        left: 0;
                                        padding: 0 30px;
                                        position: absolute;
                                        right: 0;
                                        text-transform: none;
                                        z-index: 1;
                                    }
                                    
                                    .preview_small {
                                        color: rgb(253, 248, 238);
                                        font-size: 16px;
                                        font-weight: thin;
                                        line-height: 20px;
                                        text-transform: none;
                                        z-index: 1;
                                    }
                                    
                                    .flex-caption_small_kdw {
                                        bottom: 25px;
                                        color: rgb(254, 203, 156);
                                        font-size: 20px;
                                        font-weight: thin;
                                        line-height: 22px;
                                        left: 0;
                                        padding: 0 30px;
                                        position: absolute;
                                        right: 0;
                                        text-transform: none;
                                        z-index: 1;
                                    }
                                    
                                    .flex-caption_small_kdw_mobil {
                                        bottom: 0px;
                                        color: rgb(0, 0, 0);
                                        font-size: 16px;
                                        font-weight: thin;
                                        line-height: 16px;
                                        left: 0;
                                        padding: 0 0px;
                                        margin-bottom: -20px;
                                        position: sticky;
                                        right: 0;
                                        text-transform: none;
                                        z-index: 1;
                                    }
                                    
                                    .flex-caption_sticky_desktop {
                                        bottom: 0px;
                                        color: rgb(41, 40, 40);
                                        font-size: 18px;
                                        font-weight: thin;
                                        line-height: 30px;
                                        left: 0;
                                        padding: 10 0px;
                                        margin-bottom: -20px;
                                        position: relative;
                                        right: 0;
                                        text-transform: none;
                                        z-index: 1;
                                    }
                                    
                                    .flex-caption_03 {
                                        top: 30px;
                                        color: rgb(217, 194, 171);
                                        font-size: 20px;
                                        font-weight: thin;
                                        line-height: 28px;
                                        left: 0;
                                        padding: 0 20px;
                                        position: absolute;
                                        right: 0;
                                        text-transform: none;
                                        z-index: 1;
                                    }
                                    
                                    .font_weight_300 {
                                        font-weight: 300;
                                    }
                                    
                                    .transition {
                                        transition: all 0.2s ease-in-out;
                                    }
                                    
                                    .big_font {
                                        font-size: 2rem;
                                        font-weight: 700;
                                        line-height: 25px;
                                    }
                                    
                                    .big_font_thin {
                                        font-size: 2rem;
                                        font-weight: thin;
                                        line-height: 25px;
                                    }
                                    
                                    .big_font_content {
                                        font-size: 1.5rem;
                                        line-height: 1.8rem;
                                        color: rgb(2, 60, 56);
                                    }

.big_font_content_center {
                                        font-size: 1.8rem;
                                        line-height: 1.95rem;
                                        color: rgb(2, 60, 56);
	text-align: center;
                                    }
                                    
                                    .head_font_content {
                                        font-size: 1.8rem;
                                        line-height: 1.95rem;
                                        color: rgb(2, 60, 56);
                                        font-weight: 700;
                                    }
                                    
                                    .head_font_contact {
                                        font-size: 2.8rem;
                                        line-height: 3.5rem;
                                        color: rgb(2, 60, 56);
                                        font-weight: 500;
                                        margin-top: 30px;
                                    }

.head_font_startseite {
                                        font-size: 2.7rem;
                                        line-height: 2.8rem;
                                        color: rgb(2, 60, 56);
                                        font-weight: 300;
                                        margin-top: 30px;
                                    }

.head_font_startseite_center {
                                        font-size: 2.0rem;
                                        line-height: 2.5rem;
                                        color: rgb(2, 60, 56);
                                        font-weight: 300;
                                        margin-top: 30px;
	 									text-align: center;
                                    }
                                    
                                    .headline_margin_bottom {
                                        font-size: 3rem;
                                        line-height: 3rem;
                                        color: rgb(0, 0, 0);
                                        font-weight: 500;
                                        margin-bottom: 30px;
                                    }
                                    
                                    .margin_bottom_0 {
                                        margin-bottom: 0px;
                                    }
                                    
                                    .headline_margin_bottom_small {
                                        font-size: 2rem;
                                        line-height: 2rem;
                                        color: rgb(0, 0, 0);
                                        font-weight: 500;
                                        margin-bottom: 20px;
                                        margin-top: -15px;
                                    }
                                    
                                    .head_font_contact_small {
                                        font-size: 1.5rem;
                                        line-height: 2.0rem;
                                        color: rgb(2, 60, 56);
                                        font-weight: thin;
                                    }
                                    
                                    .head_font_start {
                                        font-size: 2.2rem;
                                        line-height: 2.0rem;
                                        color: rgb(2, 60, 56);
                                        font-weight: thin;
                                    }
                                    
                                    .head_font_advent2 {
                                        font-size: 1.5rem;
                                        line-height: 1.9rem;
                                        color: rgb(0, 0, 0);
                                        font-weight: thin;
                                        margin-top: 10px;
                                    }
                                    
                                    .margin_right {
                                        margin-right: 1rem;
                                    }
                                    
                                    .margin_right_left_top {
                                        margin-right: 1rem;
                                        margin-left: 1rem;
                                        margin-top: 1rem;
                                    }
                                    
                                    .margin_right_0 {
                                        margin-right: 0rem;
                                    }
                                    
                                    .margin_top {
                                        margin-top: 2rem;
                                        padding-left: 0.0rem;
                                    }
                                    
                                    .margin_top_padding {
                                        margin-top: 1.75rem;
                                        margin-bottom: 0rem;
                                        padding-left: 0.0rem;
                                        padding-top: 2.0rem;
                                        padding-bottom: 2.0rem;
                                    }


.marker {
  background-image: url('mapbox-icon.png');
  background-size: cover;
  width: 100% !important;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
	
}

 .mapboxgl-popup-content {
    position: relative;
    background: rgba(8, 44, 42, 0.5) !important;
    border-radius: 3px;
    box-shadow: 0 1px 2px rgba(0,0,0,.1);
    padding: 10px 10px 15px;
    pointer-events: auto;
	 color: whitesmoke!important;
}   			

                                    
                                    .panel_farbe {
                                        background-color: rgba(240, 232, 195, 0.15);
                                        padding-top: 1.8rem;
                                        margin-bottom: 2.3rem;
                                    }
                                    
                                    .panel_farbe_small {
                                        background-color: rgba(240, 232, 195, 0.15);
                                        padding-top: 1.8rem;
                                        margin-bottom: 0.0rem;
                                    }
                                    
                                    .panel_preview_green {
                                        background-color: rgba(2, 60, 56, 0.84);
                                        padding-top: 1.8rem;
                                        padding-bottom: 2.0rem;
                                        margin-bottom: 0.0rem;
                                        margin-top: 0.0rem;
                                        color: white;
                                    }

									.panel_preview_green_alfa {
                                        background-color: rgba(2, 60, 56, 0.4);
                                        padding-top: 1.8rem;
										padding-bottom: 1.0rem;
                                        margin-bottom: 0.0rem;
                                        color: white;
                                    }
                                    
                                    
                                    .panel_farbe_medium {
                                        background-color: rgba(240, 232, 195, 0.21);
                                        padding-top: 1.8rem;
                                        margin-bottom: 4.0rem;
                                    }
                                    
                                    .panel_farbe2 {
                                        background-color: rgba(8, 44, 42, 0.29);
                                        margin-top: 3.0rem;
                                        margin-bottom: 0.0rem;
                                        padding-top: 2rem;
                                        padding-bottom: 2rem;
                                        border-top: 1px solid rgba(2, 60, 56, 0.35);
                                    }
                                    
                                    .panel_footer {
                                        background-color: rgba(2, 60, 56, 0.61);
                                        margin-left: 1rem;
                                        margin-top: 2rem;
                                    }
                                    
                                    .is-dropdown-submenu-item {
                                        border-bottom: 1px solid rgba(253, 252, 246, 0.5);
                                        background: rgba(2, 60, 56, 0.9);
                                    }
                                    
                                    .dropdown.menu .submenu {
                                        border: 1px solid rgba(253, 252, 246, 0.5);
                                        border-bottom: 0px;
                                    }
                                    
                                    .dropdown.menu .submenu {
                                        background: rgba(255, 255, 255, 0);
                                    }
                                    
                                    .dropdown.menu .submenu > li > a:hover {
                                        color: rgb(239, 231, 216);
                                        background: rgba(3, 52, 47, 0.9);
                                    }
                                    
                                    .is-drilldown .menu.vertical > li {
                                        border-bottom: 1px solid rgba(253, 252, 246, 0.5);
                                        background: rgba(255, 255, 255, 0);
                                    }
                                    
                                    .is-drilldown {
                                        background: rgba(2, 60, 56, 0.81);
                                    }
                                    
                                    .title-bar {
                                        background: rgb(3, 41, 42);
                                        color: rgb(239, 231, 216);
                                        padding: 0.5rem;
                                    }
                                    
                                    size_logo {
                                        height: 25px;
                                    }
                                    
                                    .right_press {
                                        position: absolute;
                                        right: 40px;
                                        top: 15px;
                                    }
                                    
                                    .right2 {
                                        position: absolute;
                                        right: 2px;
                                        top: 2px;
                                    }
                                    
                                    .right {
                                        position: absolute;
                                        right: 0px;
                                        top: 0px;
                                    }
                                    
                                    h1 {
                                        font-weight: normal;
                                        font-size: 40px;
                                        font-weight: normal;
                                        text-transform: uppercase;
                                        padding: 55px 30px;
                                        span {
                                            font-size: 13px;
                                            display: block;
                                            padding-left: 4px;
                                        }
                                    }
                                    
                                    h12 {
                                        font-weight: normal;
                                        font-size: 40px;
                                        font-weight: normal;
                                        padding: 0px 0px;
                                    }
                                    
                                    p {
                                        display: block;
                                        float: left;
                                        font-size: 20px;
                                    }
                                    
                                    a {
                                        color: rgb(1, 41, 38);
                                        text-decoration: none;
                                        line-height: inherit;
                                        cursor: pointer;
                                    }
                                    
                                    a:hover {
                                        color: rgb(239, 231, 216);
                                    }
                                    
                                    .verticalLine {
                                        padding-left: 40px;
                                        border-left: 1px solid rgba(255, 255, 255, 0.2);
                                    }
                                    
                                    hr_v {
                                        width: 1px;
                                        height: 100px;
                                        background-color: black;
                                        float: left;
                                        margin: 2 2em;
                                    }


                                    
                                    #content {
                                        height: 2000px;
                                    }
                                    
                                    #footer {
                                        position: absolute;
                                        bottom: 0;
                                        width: 100%;
                                        padding: 60px 0;
                                    }
                                    
                                    #back-top {
                                        position: fixed;
                                        bottom: 10px;
                                        right: 3%;
                                        z-index: 100;
                                        padding-left: 15px;
                                        padding-right: 15px;
                                        background-color: rgba(239, 231, 216, 0.3);
                                    }
                                    
                                    .minheight {
                                        min-height: 70%;
                                    }


@media screen and (max-width:720px) {
  #cookiebanner a.c-button { 
    width:100%; 
    box-sizing:border-box; 
    text-align:center; 
    margin-bottom:20px;} 
  #c-right { 
    float:none;
  }
  #c-left { 
    float:none;
  }   
  #cookiebanner p.c-message { 
    margin-bottom:20px;
  }   
}    

@media screen and (min-width:720px) {
  #cookiebanner #c-left { 
    float:left; 
    max-width:80%;
  }
  #cookiebanner #c-right { 
    float:right; 
  }
}

#cookiebanner { 
  box-sizing: border-box; 
  background-color:rgb(36,36,39); 
  opacity: 0.9; width:100%; 
  padding:25px 40px; 
  position: fixed; 
  z-index: 2147483645; 
  bottom:-800px
}
#cookiebanner p { 
  font-size:14px; 
  line-height:1.4;
}
#cookiebanner .c-header { 
  text-transform:uppercase; 
  color:#fff; 
  font-weight:bold; 
  margin-bottom:4px;
}
#cookiebanner p.c-message {
  font-size:14px; 
  color:#999;
}
#cookiebanner a { 
  font-weight:bold; 
  color:#999;
}
#cookiebanner a:hover { 
  color:#fff;
}

#cookiebanner a.c-button { 
  border-radius: 4px;
  background-color: rgb(255,255,255);
  box-shadow: 0 2px 0 0 rgb(221,221,221);
  border:1px solid rgb(221,221,221);
  padding:12px 60px;
  text-decoration:none;
  display:inline-block;
  vertical-align:middle;
  margin-top:10px;
  color:#666;
  font-size:16px;
  -o-transition:.2s;
  -ms-transition:.2s;
  -moz-transition:.2s;
  -webkit-transition:.2s;
  transition:.2s;
}

#cookiebanner a.c-button:hover { 
  box-shadow:none; 
  background-color:#f8f8f8;
}

